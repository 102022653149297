import React from 'react'

import Cart from '~/components/Cart'

const CartPage = () => (
	<div className="pl-80">
		<h1 className="u-h0">Cart</h1>
		<Cart />
	</div>
)

export default CartPage
